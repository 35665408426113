import axios from "axios";
import {API_URL} from "@/const";

export default {
  data() {
    return {
      generateCompanyList: [],
    };
  },

  methods: {
    // CBA排出事業者選択肢取得API  ENTRUST.CBA_GENERATOR_COMPANY
    getGeneratorChoiceApi() {
      return axios
        .get(API_URL.ENTRUST.CBA_GENERATOR_COMPANY)
        .then((res) => {
          this.generateCompanyList = res.data.generateCompanyList;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 運搬事業者情報取得API GENERATOR
    getCollectorApi(collectorId) {
      return axios
        .get(API_URL.COLLECTOR.DETAIL + collectorId)
        .then((res) => {
          const collector = res.data;
          this.formEdit.isCollectProxy = collector.isCollectProxy;
          this.selectingGeneratorsForRegist = collector.collectProxyList ?? [];
          this.selectedGeneratorsForRegist = collector.collectProxyList ?? [];
          this.selectingGeneratorForReference = collector.collectReferenceGenerateCompanyList ?? [],
          this.selectedGeneratorsForReference = collector.collectReferenceGenerateCompanyList ?? [],
          this.formEdit.isCBAContract = collector.isCbaContract;
          this.formEdit.contractStatusTypeId = collector.contractStatusInfo.id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
