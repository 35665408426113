export default {
  data() {
    return {
      form: {
        collectCompanyId: "",
        isCollectProxy: true,
        collectProxyList: [],
        collectReferenceGenerateCompanyList: [],
        memberName: "",
        memberMail: "",
        tel: "",
        mobilePhone: "",
      },
    };
  },
};
